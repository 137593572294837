body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.imagehgt {
    height: 300px!important;
    width: 100%!important;
    border-bottom-left-radius: 30%!important;
    border-bottom-right-radius: 30%!important;
}

.headingCap {
    border-radius: 50%;
    height: 250px;
    width: 250px;
    background-color: #fff;
    color: #000;
    /* text-align: inherit!important; */
    overflow-wrap: break-word!important;
    float: none;
    text-align: unset;
    padding: 16%;
    margin: 0px 0%;
    font-size: 20px;
    line-height: initial;
}

.carousel.slide {
    height: 330px;
    background-size: contain;
    background-repeat: round;
}

.text-center.carousel-caption {
    top: 170px!important;
    ;
}

.carousel-indicators li {
    border-radius: 50%!important;
    ;
    width: 20px!important;
    ;
    height: 20px!important;
    ;
}

ol.carousel-indicators {
    top: 140%;
    margin: auto !important;
}

.carousel-caption {
    left: 16%!important;
}

body#body {
    /* background-image: url(images/cover07.png); */
    /* background-repeat: round; */
    /* background-attachment: fixed; */
}

button.btn.btn-light.signupbtn {
    position: absolute;
    bottom: 15%;
    left: 0;
    margin: 0 15px;
    width: -webkit-fill-available;
}

.signupspan {
    color: #fff;
    font-size: 15px;
    position: absolute;
    text-align: center;
    bottom: 10%;
    right: 25%;
    left: 25%;
    width: 53%;
}

.signupspan a {
    color: #fff;
}

footer a.btn {
    width: 17%;
    height: 50px;
    border-radius: 15px;
}

footer img.img-rounded.img-responsive {
    height: 36px;
}

footer {
    background-color: transparent!important;
}

.abouth1 {
    color: #02486f;
    border-radius: 0!important;
}

#divInner input {
    border-radius: 10px;
}

.circubar {
    width: unset;
}

.btnincdec {
    background-color: transparent;
    border: 5px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.userhomediv a {
    height: 80px;
    width: 80px;
    background-color: transparent;
    border: none;
}

.userhomediv a img {
    background-color: transparent;
}

.userhomediv:nth-child(2) {
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
}

.userhomeProbar h2 {
    font-size: 5vw!important;
}

.userhomeProbar small {
    font-size: 3vw!important;
}

ol.carousel-indicators {
    bottom: -145px;
}

.topupform {
    height: 150px!important;
    border-radius: 15px!important;
}

.topupform .sign-inp.offset-md-4.col-md-4.col-xm-12.h-80 {
    padding-top: 15%!important;
}

.topupform .sign-inp .form-inline {
    border-radius: 15px!important;
}

div.mt-30 .btn.btn-custom.btn-lg {
    background-color: #fff!important;
    color: #0063a1!important;
    border-radius: 15px;
}

.asancolor {
    color: #f47920!important;
}

.data_info span, .data_info strong, .data_info p {
    color: #fff!important;
}

.naviconimg {
    height: 25px;
}

.rcs_slideCon .itemWrapper {
    margin: 0px 0;
}

.text-help{
    color: #888;
    font-size: 0.9rem;
}

@media screen and (max-width: 600px) and (min-width: 360px) {
    img.img-responsive.banner-image {
        width: 321px !important;
        height: 167px !important;
    }
    .imagehgt {
        height: 310px !important;
    }
    .headingCap {
        margin: auto;
        width: 235px !important;
        height: 235px !important;
        padding: 68px 20px 10px 20px;
        font-size: 20px
    }
    ol.carousel-indicators {
        margin: auto !important;
        bottom: -100px !important;
    }
    .signupspan {
        bottom: 7%;
    }
    .vas-sercices-h2 {
        margin-top: 16px;
        margin-bottom: 12px;
    }
    .carousel-inner {
        height: 130% !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 600px) {
    img.img-responsive.banner-image {
        width: 199px !important;
        height: 212px !important;
    }
    .carousel-inner {
        height: 162% !important;
    }
    .imagehgt {
        height: 390px !important;
    }
    .headingCap {
        width: 340px !important;
        height: 340px !important;
        margin: auto;
        font-size: 30px;
        padding: 128px 43px 132px 43px;
    }
    ol.carousel-indicators {
        top: 280%;
        margin: auto !important;
    }
    .gift-data-tag {
        font-size: 45px !important;
    }
    .gift-data-container {
        padding-top: 26px !important;
        height: 173px !important;
    }
    div#giftdata-span {
        padding-top: 56px !important;
        font-size: 25px !important;
        float: right !important;
        width: 90px !important;
    }
    div#label {
        background-color: white !important;
        font-size: 30px !important;
        width: 67px !important;
        height: 67px !important;
        border-radius: 10px !important;
        padding-top: 10px !important;
    }
    div#giftdata-form {
        padding-top: 56px !important;
    }
    input.form-control-lg.form-gift-data.form-control {
        height: 65px !important;
    }
    div#input-type {
        height: 229px !important;
    }
    .row.data-range {
        height: 236 !important;
        padding-top: 53px !important;
    }
    .Recharge-via-Tohfa {
        margin-top: 70px;
    }
    .vas-sercices-h2 {
        margin-top: 10px;
        font-size: 40px;
        margin-bottom: 18px;
    }
}

@media (min-width: 991px) {
    .banner-image {
        width: 279px !important;
        height: 248px !important;
    }
    .carousel-inner {
        overflow: unset!important;
    }
    .imagehgt {
        height: 515px !important;
    }
    .headingCap {
        font-size: 36px;
        padding: 170px 45px 132px 45px;
        width: 460px !important;
        height: 460px !important;
        margin: auto;
    }
    ol.carousel-indicators {
        top: 350%;
        margin: auto !important;
    }
    .text-center.carousel-caption {
        top: 264px!important;
    }
    .gift-data-tag {
        font-size: 70px !important;
    }
    .container-fluid.p-0.m-0.gift-data-container {
        height: 167px;
    }
    #input-type {
        padding-top: 28px !important;
    }
    div#label {
        background-color: white !important;
        height: 63px !important;
        border-radius: 10px !important;
        padding-top: 11px !important;
        font-size: 27px !important;
    }
    div#giftdata-span {
        width: 85px !important;
    }
    input.form-control-lg.form-gift-data.form-control {
        height: 65px;
    }
    .row.data-range {
        height: 273px;
        padding-top: 76px;
    }
    .Recharge-via-Tohfa {
        margin-top: 100px;
    }
    .vas-sercices-h2 {
        margin-top: 38px;
        font-size: 56px;
        margin-bottom: 18px;
    }
}

.bundleActivateparagraph {
    font-size: 13px;
    line-height: 1.4;
}

.bundleActivatebutton {
    margin-bottom: 86px;
}

.bundleActivate {
    margin-top: -17px;
}

.activateBtn {
    background-color: #f47920 !important;
    border: 1px solid white !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
}

.modal-content {
    border-radius: 19px !important;
}

.container_fluid.cstm-slider {
    padding: 0;
    background: transparent;
}

#input-type {
    height: 110px;
    padding: 0px;
    margin: 0px;
}

#giftdata-form {
    padding-top: 25px;
    width: 82%;
}

.hr {
    border-top: 2px solid #36b6e9;
    width: 100%;
}

.gift-data-tag {
    font-size: 30px;
}

div#giftdata-span {
    color: #215e7d;
    padding-top: 27px;
    padding-right: 14px;
    font-size: 19px;
    width: 64px;
    float: right;
    font-size: 19px;
}

div#label {
    background-color: white;
    height: 46px;
    border-radius: 10px;
    padding-top: 10px;
}

#round {
    margin-top: 12px;
    font-size: 16px;
}

@media screen and (max-width: 360px) and (min-width: 320px) {
    .text-center.carousel-caption {
        top: 80px!important;
    }
    img.h-80.imagehgt {
        height: 223px !important;
    }
    .headingCap {
        width: 150px !important;
        height: 150px ! important;
        font-size: 14px;
        padding: 35px 28px 10px 28px;
        margin: auto;
    }
    .carousel-inner {
        height: 90%;
    }
    .text-center.carousel-caption {
        top: 122px!important;
    }
    ol.carousel-indicators {
        bottom: 82px;
    }
    span.signupspan {
        bottom: 5%;
    }
    .headingCap p.pt-2 {
        font-size: 12px;
    }
}

img.img-responsive.banner-image {
    width: 100% !important;
    height: 167px !important;
}

span.logo-home {
    margin-left: -20px;
    font-size: 21px;
}

.banner-image {
    border-radius: 15px;
}

.navbar-toggler-icon {
    margin-top: -6px;
}

.user-logo {
    font-size: 19px;
}

.home-toggle-btn {
    margin-top: 8px;
}